var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ==============================|| OVERRIDES - INPUT BORDER & SHADOWS ||============================== //
function getColor(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var light = colors.light;
    var shadows = theme.customShadows[color];
    return {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: light
        },
        '&.Mui-focused': {
            boxShadow: shadows,
            '& .MuiOutlinedInput-notchedOutline': {
                border: "1px solid ".concat(light)
            }
        }
    };
}
// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //
export default function OutlinedInput(theme) {
    return {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '10.5px 14px 10.5px 12px'
                },
                notchedOutline: {
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300]
                },
                root: __assign(__assign({ padding: 0 }, getColor({ color: 'primary', theme: theme })), { '&.Mui-error': __assign({}, getColor({ color: 'error', theme: theme })) }),
                inputSizeSmall: {
                    padding: '7.5px 8px 7.5px 12px'
                },
                inputMultiline: {
                    padding: '10.5px 14px 10.5px 12px'
                },
                colorSecondary: getColor({ color: 'secondary', theme: theme }),
                colorError: getColor({ color: 'error', theme: theme }),
                colorWarning: getColor({ color: 'warning', theme: theme }),
                colorInfo: getColor({ color: 'info', theme: theme }),
                colorSuccess: getColor({ color: 'success', theme: theme })
            }
        }
    };
}
