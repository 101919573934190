var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ==============================|| OVERRIDES - TABLE CELL ||============================== //
export default function TableCell(theme) {
    var commonCell = {
        '&:not(:last-of-type)': {
            '&:after': {
                position: 'absolute',
                content: '""',
                backgroundColor: theme.palette.divider,
                width: 1,
                height: 'calc(100% - 30px)',
                right: 0,
                top: 16
            }
        }
    };
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    padding: 12,
                    borderColor: theme.palette.divider,
                    '&.cell-right': {
                        justifyContent: 'flex-end',
                        textAlign: 'right',
                        '& > *': {
                            justifyContent: 'flex-end',
                            margin: '0 0 0 auto'
                        },
                        '& .MuiOutlinedInput-input': {
                            textAlign: 'right'
                        }
                    },
                    '&.cell-center': {
                        justifyContent: 'center',
                        textAlign: 'center',
                        '& > *': {
                            justifyContent: 'center',
                            margin: '0 auto'
                        }
                    }
                },
                sizeSmall: {
                    padding: 8
                },
                head: __assign({ fontSize: '0.75rem', fontWeight: 700, textTransform: 'uppercase' }, commonCell),
                footer: __assign({ fontSize: '0.75rem', textTransform: 'uppercase' }, commonCell)
            }
        }
    };
}
