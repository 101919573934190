var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
// assets
import { CheckSquareFilled, MinusSquareFilled } from '@ant-design/icons';
// ==============================|| RADIO - COLORS ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, main = colors.main, dark = colors.dark;
    return {
        '&:hover': {
            backgroundColor: lighter,
            '& .icon': {
                borderColor: main
            }
        },
        '&.Mui-focusVisible': {
            outline: "2px solid ".concat(dark),
            outlineOffset: -4
        }
    };
}
function getSizeStyle(size) {
    switch (size) {
        case 'small':
            return { size: 16, fontSize: 1, position: 1 };
        case 'large':
            return { size: 24, fontSize: 1.6, position: 2 };
        case 'medium':
        default:
            return { size: 20, fontSize: 1.35, position: 2 };
    }
}
// ==============================|| CHECKBOX - STYLE ||============================== //
function checkboxStyle(size) {
    var sizes = getSizeStyle(size);
    return {
        '& .icon': {
            width: sizes.size,
            height: sizes.size,
            '& .filled': {
                fontSize: "".concat(sizes.fontSize, "rem"),
                top: -sizes.position,
                left: -sizes.position
            }
        }
    };
}
// ==============================|| OVERRIDES - CHECKBOX ||============================== //
export default function Checkbox(theme) {
    var palette = theme.palette;
    return {
        MuiCheckbox: {
            defaultProps: {
                className: 'size-small',
                icon: _jsx(Box, { className: "icon", sx: { width: 16, height: 16, border: '1px solid', borderColor: 'inherit', borderRadius: 0.25 } }),
                checkedIcon: (_jsx(Box, { className: "icon", sx: {
                        width: 16,
                        height: 16,
                        border: '1px solid',
                        borderColor: 'inherit',
                        borderRadius: 0.25,
                        position: 'relative'
                    }, children: _jsx(CheckSquareFilled, { className: "filled", style: { position: 'absolute' } }) })),
                indeterminateIcon: (_jsx(Box, { className: "icon", sx: {
                        width: 16,
                        height: 16,
                        border: '1px solid',
                        borderColor: 'inherit',
                        borderRadius: 0.25,
                        position: 'relative'
                    }, children: _jsx(MinusSquareFilled, { className: "filled", style: { position: 'absolute' } }) }))
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    color: palette.secondary[300],
                    '&.size-small': __assign({}, checkboxStyle('small')),
                    '&.size-medium': __assign({}, checkboxStyle('medium')),
                    '&.size-large': __assign({}, checkboxStyle('large'))
                },
                colorPrimary: getColorStyle({ color: 'primary', theme: theme }),
                colorSecondary: getColorStyle({ color: 'secondary', theme: theme }),
                colorSuccess: getColorStyle({ color: 'success', theme: theme }),
                colorWarning: getColorStyle({ color: 'warning', theme: theme }),
                colorInfo: getColorStyle({ color: 'info', theme: theme }),
                colorError: getColorStyle({ color: 'error', theme: theme })
            }
        }
    };
}
