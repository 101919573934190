import { Link } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import { NavigationItem, useGetCrumbs } from './hook';

export type OverrideIcon =
  | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
      muiName: string;
    })
  | React.ComponentClass<any>
  | React.FunctionComponent<any>;

export type BreadcrumbsProps = {
  card: boolean;
  icon: boolean;
  maxItems: number;
  navigation: NavigationItem[];
  rightAlign: boolean;
  separator?: OverrideIcon;
};

const PageHeader = (props: BreadcrumbsProps) => {
  const { card, icon, maxItems, navigation, rightAlign, separator: SeparatorIcon } = props;
  const { main, item, hideMainCard } = useGetCrumbs(navigation);

  // item separator
  const separatorIcon = SeparatorIcon ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  const marginSX = hideMainCard ? {} : { mb: 3 };

  if (!item) return <Typography />;

  // main
  return (
    <Grid
      container
      direction={rightAlign ? 'row' : 'column'}
      justifyContent={rightAlign ? 'space-between' : 'flex-start'}
      alignItems={rightAlign ? 'center' : 'flex-start'}
      spacing={1}
    >
      <Grid item>
        <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
          {main.map((breadcrumb, i) => {
            const isLast = i + 1 == main.length;
            const crumbColor = isLast ? 'textPrimary' : 'textSecondary';
            const linkProps = isLast || !breadcrumb.link ? {} : { component: Link, to: breadcrumb.link };
            return (
              <Typography key={breadcrumb.title} color={crumbColor} variant="h6" sx={{ textDecoration: 'none' }} {...linkProps}>
                {breadcrumb.title}
              </Typography>
            );
          })}
        </MuiBreadcrumbs>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: card === false ? 0.25 : 1 }}>
        <Grid item sx={{ ml: 1 }}>
          {!hideMainCard && (
            <Typography sx={{ mb: 3 }} variant="h2" data-testid={`page-title-${item.toLowerCase()}`}>
              {item}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
